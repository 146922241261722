import { useContext } from "react";
import TipsTable from "../../components/TipsTable/TipsTable";
import TipsProvider from "../../contexts/TipsContext";
import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ReusableComponents/ErrorMessage/ErrorMessage";

function NewTips() {
  const { data, loading: isLoading, errorMsg } = useContext(TipsProvider);
  if (isLoading) return <SpinnerFullPage />;
  return (
    <div>
      {errorMsg && <ErrorMessage errorMessage={errorMsg} />}
      <TipsTable lock={true} data={data.data.activeTips} />
      <TipsTable data={data.data.tips} />
    </div>
  );
}

export default NewTips;
