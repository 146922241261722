import { useRef, useState } from "react";
import styles from "./Faq.module.css";

const FAQs = [
  {
    question: "Who we are?",
    answer:
      "We are a community of professional bettors who share their information on matches, why it is advantageous to bet a certain tip. For a small fee, you too can become part of the community and learn how to be successful in betting",
  },
  {
    question: "Why choose Topmatchpredict?",
    answer:
      "Topmatchpredict is unique, because give you only for one fee all information from all bettors on the site. Most of our tipsters are dedicated to betting only on certain sports or even only certain leagues. Thanks to this, they have detailed information about teams and matches and can thus give you quality tips. All tips are listed with reasons to bet it",
  },
  {
    question: "How can I buy tips?",
    answer:
      "To purchase the service, you must register on our platform and then make order here “link on the site”",
  },
  {
    question: "How is payment made?",
    answer:
      "Payment for now is made through bitcoin. After ordering, you will receive a payment request by email. After successful payment, you will receive a receipt confirming the payment. ",
  },
  {
    question: "What is the price?",
    answer:
      "There are three options to purchase a membership. 30EUR for 30 days, 75EUR for 90 days and 240EUR for 365 days",
  },
  {
    question: "How do I access tips?",
    answer:
      "There are two ways to get tips. The first way is to display tips directly on the Topmatchpredict platform, where you will see all active tips in an overview after logging in. Tips are also sent automatically to the e-mail address provided during registration. To the e-mail you can choose, who you want to receive tips from.",
  },
  {
    question: "What do you do with your membership money?",
    answer:
      "Small part of the received money will be used for the costs and maintenance of the website. The rest will be divided fairly between tipsters based on their results.",
  },
  {
    question: "Which bookmakers should I use for betting?",
    answer:
      "Most of the tips can be bet on all global or local bookmakers. We recommend having more accounts, because of dropping odds. Sometimes can be add tip, which offer only a few bookmakers, specially some kind of statistic bet. But in the amount of published tips it will be a minority. For example we recommend Bet365, Pinnacle, Unibet………….",
  },
  {
    question: "How can I contact you?",
    answer: "Contact e-mail address “email”.",
  },
];

function Accordion({ title, children }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={styles.accordionWrapper}>
      <div
        className={`${styles.accordionTitle} ${isOpen ? styles.open : ""}`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`${styles.accordionItem} ${!isOpen ? styles.collapsed : ""}`}>
        <div className={styles.accordionContent}>{children}</div>
      </div>
    </div>
  );
}

function Faq() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headline}>
        <h1> Frequently asked questions</h1>
        <p>
          Do you need advice? Below we have written answers to frequently asked questions for you.
          And if you have a less common one? Feel free to contact us “email” and will be happy to
          answer everything.
        </p>
      </div>
      {FAQs.map((faq) => (
        <Accordion title={faq.question}>{faq.answer}</Accordion>
      ))}
    </div>
  );
}

export default Faq;
