import styles from "./Unique.module.css";

function Unique() {
  return (
    <div className={styles.unique}>
      <h1>What makes topmatch unique?</h1>
      <div className={styles.points}>
        <div>
          <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_61_41)">
              <g clip-path="url(#clip1_61_41)">
                <path
                  d="M52.2309 0.539062H17.7693C8.25305 0.539062 0.538574 8.25354 0.538574 17.7698V52.2314C0.538574 61.7477 8.25305 69.4622 17.7693 69.4622H52.2309C61.7472 69.4622 69.4617 61.7477 69.4617 52.2314V17.7698C69.4617 8.25354 61.7472 0.539062 52.2309 0.539062Z"
                  fill="#09124F"
                />
                <g clip-path="url(#clip2_61_41)">
                  <path
                    d="M36.8816 49.7451C36.8816 50.4382 37.4434 51 38.1365 51H45.9794C46.6725 51 47.2343 50.2934 47.2343 49.6003C47.2343 48.9073 46.6725 48.4902 45.9795 48.4902H38.1365C37.4434 48.4902 36.8816 49.0521 36.8816 49.7451Z"
                    fill="white"
                  />
                  <path
                    d="M41.9011 44.0981C41.2081 44.0981 40.6462 44.66 40.6462 45.353C40.6462 46.0461 41.2081 46.6079 41.9011 46.6079H49.7441C50.4371 46.6079 50.999 45.9013 50.999 45.2082C50.999 44.5152 50.4371 44.0981 49.7441 44.0981H41.9011Z"
                    fill="white"
                  />
                  <path
                    d="M37.2615 28.4123C37.2615 27.8934 36.8394 27.4712 36.3204 27.4712H35.3793V29.3535H36.3204C36.8394 29.3535 37.2615 28.9313 37.2615 28.4123Z"
                    fill="#045ED6"
                  />
                  <path
                    d="M38.2027 32.177C38.2027 31.658 37.7805 31.2358 37.2615 31.2358C36.6032 31.2358 36.0376 31.2358 35.3793 31.2358V33.1182H37.2615C37.7805 33.1182 38.2027 32.696 38.2027 32.177Z"
                    fill="#045ED6"
                  />
                  <path
                    d="M40.0151 39.7061C39.3221 39.7061 38.7639 40.2679 38.7639 40.9609C38.7639 41.654 39.3257 42.2158 40.0188 42.2158H47.8618C48.5548 42.2158 49.1167 41.5092 49.1167 40.8162C49.1167 40.1231 48.5582 39.7061 47.8652 39.7061C45.8718 39.7061 42.0092 39.7061 40.0151 39.7061Z"
                    fill="white"
                  />
                  <path
                    d="M19.8265 42.7953C18.7257 43.8962 18.7257 45.6874 19.8265 46.7883C20.9272 47.8891 22.7187 47.8893 23.8196 46.7883L27.3893 43.2186L23.3963 39.2256L19.8265 42.7953Z"
                    fill="#045ED6"
                  />
                  <path
                    d="M36.8816 37.8238H44.7309C51.2171 30.7554 46.0111 18.9221 36.3202 19.0004C26.784 18.9188 21.5095 30.4494 27.7005 37.5834L26.0583 39.2256L27.3893 40.5566L29.0315 38.9144C31.1637 40.7201 33.9309 41.7198 36.8817 41.5747V37.8238H36.8816ZM35.3792 35.9415V35.0003C34.3478 35.0003 33.3604 35.0003 32.5558 35.0003V33.118H33.4969C33.4969 32.5668 33.4969 28.0281 33.4969 27.471H32.5558V25.5887H35.3792V24.6476H37.2615V25.7506C38.9253 26.3407 39.6355 28.2839 38.779 29.7977C41.1281 31.2961 40.0847 34.9863 37.2615 35.0003V35.9415H35.3792Z"
                    fill="#045ED6"
                  />
                </g>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_61_41">
                <rect width="70" height="70" fill="white" />
              </clipPath>
              <clipPath id="clip1_61_41">
                <rect width="70" height="68.9231" fill="white" transform="translate(0 0.538574)" />
              </clipPath>
              <clipPath id="clip2_61_41">
                <rect width="32" height="32" fill="white" transform="translate(19 19)" />
              </clipPath>
            </defs>
          </svg>
          <h3>Analyzed bets</h3>
        </div>

        <div>
          <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_43_275)">
              <g clip-path="url(#clip1_43_275)">
                <g clip-path="url(#clip2_43_275)">
                  <path
                    d="M52.231 0.539062H17.7695C8.25317 0.539062 0.538696 8.25354 0.538696 17.7698V52.2314C0.538696 61.7477 8.25317 69.4621 17.7695 69.4621H52.231C61.7473 69.4621 69.4618 61.7477 69.4618 52.2314V17.7698C69.4618 8.25354 61.7473 0.539062 52.231 0.539062Z"
                    fill="#09124F"
                  />
                  <rect
                    x="42.292"
                    y="33.542"
                    width="5.83333"
                    height="16.0417"
                    rx="2.91667"
                    fill="white"
                    fill-opacity="0.8"
                  />
                  <rect
                    x="32.0837"
                    y="27.7085"
                    width="5.83333"
                    height="21.875"
                    rx="2.91667"
                    fill="#045ED6"
                  />
                  <rect
                    x="21.8754"
                    y="20.417"
                    width="5.83333"
                    height="29.1667"
                    rx="2.91667"
                    fill="#045ED6"
                  />
                </g>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_43_275">
                <rect width="70" height="70" fill="white" />
              </clipPath>
              <clipPath id="clip1_43_275">
                <rect
                  width="70"
                  height="70"
                  fill="white"
                  transform="translate(0.00012207 0.000488281)"
                />
              </clipPath>
              <clipPath id="clip2_43_275">
                <rect
                  width="70"
                  height="68.9231"
                  fill="white"
                  transform="translate(0.00012207 0.539551)"
                />
              </clipPath>
            </defs>
          </svg>
          <h3>Transparent Statics</h3>
        </div>
        <div>
          <svg
            width="71"
            height="70"
            viewBox="0 0 71 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_43_285)">
              <g clip-path="url(#clip1_43_285)">
                <g clip-path="url(#clip2_43_285)">
                  <path
                    d="M52.5112 0.539551H18.0496C8.53333 0.539551 0.818848 8.25403 0.818848 17.7703V52.2319C0.818848 61.7482 8.53333 69.4626 18.0496 69.4626H52.5112C62.0275 69.4626 69.7419 61.7482 69.7419 52.2319V17.7703C69.7419 8.25403 62.0275 0.539551 52.5112 0.539551Z"
                    fill="#09124F"
                  />
                  <path
                    d="M24.5114 38.7467C24.5114 49.1369 24.5114 49.2295 24.688 49.7507C25.108 51.0129 26.1267 52.0058 27.363 52.3676C27.7669 52.4861 28.7264 52.5023 35.2978 52.5023C42.6424 52.5023 42.7857 52.5023 43.2994 52.3256C44.5604 51.9056 45.5534 50.8869 45.9152 49.6506C46.0337 49.2381 46.0498 48.0352 46.0498 38.7295V28.2715H24.5114V38.7467ZM35.9289 32.5619C36.2477 32.7558 36.4587 33.0584 36.5341 33.4203C36.5847 33.6561 36.644 33.7067 37.1318 33.8661C38.1743 34.2193 38.8904 34.6576 39.109 35.0863C39.3783 35.5999 39.2178 36.3139 38.7644 36.6769C38.2755 37.0646 37.6789 37.0646 37.1318 36.6769C36.6601 36.3484 35.8697 36.1632 35.0964 36.1966C34.4557 36.2224 34.3383 36.2472 34.1444 36.4335C33.9764 36.5929 33.9344 36.7027 33.9506 36.9375C33.985 37.3155 34.1875 37.4167 35.7017 37.8206C37.5432 38.3009 38.4607 38.8393 38.94 39.7224C39.1424 40.0929 39.193 40.3029 39.2178 40.8833C39.2437 41.271 39.2092 41.7836 39.1597 42.0356C38.94 42.9866 38.0569 43.9116 37.0306 44.2649C36.6515 44.3919 36.5761 44.4501 36.5352 44.6698C36.2563 46.0321 34.3049 46.0235 34.026 44.6526C33.9764 44.4081 33.9183 44.3661 33.4294 44.2067C31.94 43.7275 31.3261 43.1966 31.3261 42.3889C31.3261 41.3464 32.3869 40.8241 33.3787 41.3873C34.0518 41.775 34.6829 41.9258 35.4906 41.8849C36.0969 41.859 36.2229 41.8343 36.4081 41.6576C36.4781 41.5973 36.534 41.5224 36.5717 41.4381C36.6095 41.3537 36.6283 41.2622 36.6267 41.1698C36.6267 40.9329 36.5761 40.8747 36.3144 40.7401C36.1389 40.6475 35.4658 40.4375 34.8261 40.2695C32.9587 39.773 32.1252 39.2766 31.6212 38.359C31.3843 37.9304 31.3681 37.8464 31.3681 36.9795C31.3681 36.1546 31.394 36.0038 31.5867 35.5903C31.7768 35.1798 32.0477 34.8118 32.3832 34.5084C32.7186 34.2049 33.1118 33.9722 33.5392 33.8241C33.9097 33.6895 33.985 33.6303 34.027 33.4116C34.0832 33.1218 34.2396 32.8609 34.4688 32.6749C34.698 32.4888 34.9854 32.3893 35.2806 32.3939C35.5067 32.3939 35.7684 32.4618 35.9289 32.5619Z"
                    fill="white"
                    fill-opacity="0.8"
                  />
                  <path
                    d="M16.4343 30.6515C16.4343 32.5792 16.4591 33.1263 16.5689 33.4967C16.9308 34.7416 17.9829 35.8013 19.2182 36.189C19.6059 36.3064 19.9849 36.3484 20.7754 36.3484H21.8189V28.2715H16.4343V30.6515Z"
                    fill="#045ED6"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.1944 17.6604C17.9742 18.0567 16.9307 19.125 16.5688 20.3527C16.459 20.7232 16.4342 21.2702 16.4342 23.1969V25.5779H54.1265V23.1979C54.1265 21.2702 54.1017 20.7232 53.9919 20.3527C53.6301 19.1078 52.5779 18.0481 51.3427 17.6604C50.8451 17.5096 50.3153 17.501 35.2556 17.5096C20.3801 17.5096 19.6477 17.5182 19.1944 17.6604ZM47.9848 20.4119C48.8851 20.9418 48.8851 22.1372 47.9848 22.667C47.7156 22.8264 47.5551 22.8436 46.0496 22.8436C44.5441 22.8436 44.3836 22.8264 44.1144 22.667C43.0547 22.0532 43.2819 20.5724 44.4762 20.2687C44.6194 20.2364 45.4099 20.2105 46.2176 20.2181C47.5304 20.2353 47.7253 20.2525 47.9848 20.4119Z"
                    fill="#045ED6"
                  />
                  <path
                    d="M48.7422 36.3484V28.2715H54.1268V30.6515C54.1268 32.5792 54.102 33.1263 53.9922 33.4967C53.6303 34.7416 52.5782 35.8013 51.343 36.189C50.9553 36.3064 50.5762 36.3484 49.7857 36.3484H48.7422Z"
                    fill="#045ED6"
                  />
                </g>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_43_285">
                <rect width="70" height="70" fill="white" transform="translate(0.280029)" />
              </clipPath>
              <clipPath id="clip1_43_285">
                <rect
                  width="70"
                  height="70"
                  fill="white"
                  transform="translate(0.280151 0.000488281)"
                />
              </clipPath>
              <clipPath id="clip2_43_285">
                <rect
                  width="70"
                  height="68.9231"
                  fill="white"
                  transform="translate(0.280273 0.538574)"
                />
              </clipPath>
            </defs>
          </svg>
          <h3>Quick and easy payouts</h3>
        </div>
      </div>
    </div>
  );
}

export default Unique;
