import { createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import useSendGetRequest from "../hooks/useSendGetRequest";

// const backendURL = "http://127.0.0.1:8000/api/";
const backendURL = "https://topmatchpredict-f1c26a0a7d2a.herokuapp.com/api/";
const AuthenticationContext = createContext();

export default AuthenticationContext;

export const AuthenticationProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens") ? jwtDecode(localStorage.getItem("authTokens")) : null
  );
  let user_id;
  if (!user) {
    user_id = null;
  } else {
    user_id = user["user_id"];
  }
  const { errorMsg, loading, data } = useSendGetRequest(`app-user-info/${user_id}/`);
  console.log(data);
  const [loadingLogin, setLoadingLogin] = useState(null);
  const [errorLogin, setErrorLogin] = useState(null);

  // LOGIN USER
  async function loginUser(e) {
    e.preventDefault();

    try {
      setLoadingLogin(true);
      const response = await fetch(`${backendURL}login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: e.target.username.value,
          password: e.target.password.value,
        }),
      });
      console.log(e.target.password.value);
      const data = await response.json();
      console.log(data);
      if (!response.ok) {
        console.log(data);
        throw new Error("Username or password is not correct! Please double check and try again.");
      }

      setErrorLogin(null);
      setUser(jwtDecode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));

      navigate("/");

      //   console.log(data);
    } catch (error) {
      setErrorLogin(error.message);
      console.error(error);
    } finally {
      setLoadingLogin(null);
    }
  }

  // LOGOUT USER
  const logoutUser = () => {
    navigate("/login");
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate("/login");
  };

  // FINAL DATA
  const contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    loadingLogin: loadingLogin,
    errorLogin: errorLogin,
    backendURL,
    errorMsg,
    data,
    loading,
  };

  return (
    <AuthenticationContext.Provider value={contextData}>{children}</AuthenticationContext.Provider>
  );
};
