import MembershipPackage from "./MembershipPackage";
import styles from "./MembershipPlans.module.css";

function MembershipPlans({ handleBuy }) {
  return (
    <div className={styles.membershipWrapper}>
      <h1 id={styles.headline}>actual membership price list</h1>
      <p id={styles.subheadline}>
        Track the success and activity of our top tipsters with the following key metrics
      </p>

      <div className={styles.packages}>
        <MembershipPackage
          title={"30 days"}
          price={30}
          onClick={() => handleBuy("One Month Plan")}
        />
        <MembershipPackage
          title={"90 days"}
          price={90}
          onClick={() => handleBuy("3 Months Plan")}
          mode={"dark"}
        />
        <MembershipPackage
          title={"365 days"}
          price={300}
          onClick={() => handleBuy("Full Year Plan")}
          mode={"dark"}
        />
      </div>
    </div>
  );
}

export default MembershipPlans;
