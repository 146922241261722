import { useParams } from "react-router-dom";
import styles from "./Tip.module.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ReusableComponents/ErrorMessage/ErrorMessage";
import { createRef, useCallback, useState } from "react";
import SportIcon from "../../components/TipsTable/SportIcon";

function Tip() {
  const { id } = useParams();
  const { data, errorMsg, loading } = useSendGetRequest(`get-tip-details/${id}/`);
  const [height, setHeight] = useState();

  const measuredRef = useCallback((node) => {
    console.log(node);
    if (node !== null) {
      const heightElement = document.querySelector("#analysis").scrollHeight;
      setHeight(heightElement);
    }
  }, []);
  const tipData = data?.["data"][0];

  if (loading || !data) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMsg={errorMsg} />;
  return (
    <div className={styles.tipDetails}>
      <div className={styles.heading}>
        <div>
          <SportIcon sport={tipData.sport} />
          <h1>{tipData.match_name}</h1>
        </div>

        <p> Tipster2 ROI: {tipData.roi}%</p>
      </div>
      <div className={styles.body}>
        <div className={styles.tipData}>
          <label>Date</label>
          <DatePicker id="date" value={tipData.date} dateFormat="dd/MM/yyyy" />

          <label>Sport</label>
          <input type="text" disabled value={tipData.sport} />

          <label>League</label>
          <input type="text" disabled value={tipData.league} />

          <div className={styles.middleRow}>
            <div>
              <label>Tip</label>
              <input type="text" disabled value={tipData.tip} />
            </div>
            <div>
              <label>Odd</label>
              <input type="text" disabled value={tipData.odd} />
            </div>
            <div>
              <label>Unit</label>
              <input type="text" disabled value={tipData.unit} />
            </div>
          </div>

          <label>Bookmaker</label>
          <input type="text" disabled value={tipData.bookmaker} />
        </div>
        <div className={styles.analysis}>
          <h3>Analysis</h3>
          <textarea
            disabled
            value={tipData.analysis}
            ref={measuredRef}
            style={{ height: height }}
            id="analysis"
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default Tip;
