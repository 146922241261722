import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./Navbar.module.css";

import Logo from "../../Images/finallogo.png";

import AuthenticationProvider from "../../contexts/AuthenticationContext";

function Navbar() {
  const { logoutUser, user } = useContext(AuthenticationProvider);
  const [showDropdown, setShowDropdown] = useState(false);
  function handleDropdown() {
    setShowDropdown((current) => !current);
  }
  return (
    <navbar className={styles.wrapperNav}>
      <div className={styles.navbar}>
        <div className={styles.logoWrap}>
          <Link to="/">
            <img src={Logo} alt="logo" id={styles.logo} />
          </Link>
        </div>
        <div className={styles.linksWrap}>
          {!showDropdown && (
            <div
              className={`${styles.hamburger} ${showDropdown && styles.dropdownHidden} `}
              onClick={handleDropdown}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
          {showDropdown && (
            <div className={styles.close} onClick={handleDropdown}>
              X
            </div>
          )}
          {!showDropdown && (
            <div className={styles.links}>
              <Link to="/">Home</Link>
              <Link to="/tips">Tips</Link>
              <Link to="/stats">Stats</Link>
              <Link to="/faq">FAQ</Link>
            </div>
          )}
        </div>

        <div className={styles.btns}>
          {user ? (
            <Link onClick={logoutUser} id={styles.btns}>
              Logout
            </Link>
          ) : (
            <>
              <Link to="/login" id={styles.btns}>
                Login
              </Link>
              <Link to="/register" id={styles.btns}>
                Register
              </Link>
            </>
          )}
        </div>
      </div>
      {showDropdown && (
        <div className={styles.hamburgerLinks}>
          <Link to="/">Home</Link>
          <Link to="/tips">Tips</Link>
          <Link to="/stats">Stats</Link>
          <Link to="/faq">FAQ</Link>
        </div>
      )}
    </navbar>
  );
}

export default Navbar;
