import { Link } from "react-router-dom";
import dummyStatImage from "../../Images/dummyStatImage.png";
import styles from "./NewStats.module.css";
import AuthenticationProvider from "../../contexts/AuthenticationContext";
import { useContext, useState } from "react";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import { default as ApexStockChart } from "react-apexcharts";

function NewStats() {
  const { data, loading } = useSendGetRequest("tipsters/");
  const { data: chartData, loading: isLoading } = useSendGetRequest("chart-tipster-data/");

  console.log(chartData);
  const { backendURL, user, data: userData } = useContext(AuthenticationProvider);
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  async function handleFollow(e, id) {
    e.preventDefault();
    console.log(id);
    try {
      const response = await fetch(`${backendURL}follow/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          username: user["username"],
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error("Username or password is not correct! Please double check and try again.");
      }
      setSuccessMsg(data["successMsg"]);
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  }

  if (loading) return <SpinnerFullPage />;
  const options = {
    options: {
      chart: {
        type: "line",

        animations: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "$" + value.toFixed(0);
            },
          },
        },
      },
    },
    series: [
      {
        name: `Total Profit`,
        data: chartData["data"]?.map((data) => ({
          x: data.date,
          y: data.profit,
        })),
      },
    ],
  };
  const tipsters = data["data"];
  console.log(tipsters);
  return (
    <div className={styles.statsWrapper}>
      <div className={styles.heading}>
        <h1>Stats</h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book.
        </p>
        <div id={styles.chart}>
          <ApexStockChart
            options={options.options}
            series={options.series}
            type="line"
            height="300px"
          />
        </div>
      </div>

      <div className={styles.tipsterWrap}>
        <h3 className={styles.tipsterHeading}>TIPSTER RANKING</h3>
        <ul className={styles.tipsterList}>
          <>
            {tipsters?.map((tipster) => (
              <Link to={`/tipster/${tipster.id}`} key={1}>
                <li className={styles.tipster}>
                  <div className={styles.wrap}>
                    <div className={styles.left}>
                      <img
                        src={`${backendURL.replace("/api/", "")}${tipster.profile_picture}`}
                        alt="tipster"
                      />

                      <div className={styles.rightText}>
                        <h3 id={styles.matchName}>{tipster.username}</h3>

                        <p className={styles.time}>{tipster.sports}</p>
                      </div>
                    </div>
                    <div className={styles.data}>
                      <h3>Analysis </h3>
                      <p>{tipster.number_of_analysis}</p>
                    </div>
                    <div className={styles.data}>
                      <h3>Wins</h3>
                      <p>{tipster.wins}</p>
                    </div>
                    <div className={styles.data}>
                      <h3>Losses</h3>
                      <p>{tipster.losses}</p>
                    </div>
                  </div>
                  <div className={styles.wrap}>
                    <div className={styles.data} id={styles.last}>
                      <div className={styles.innerData}>
                        <h3>ROI:</h3>
                        <p>{tipster.roi}</p>
                      </div>
                      <div className={styles.innerData}>
                        <h3>Profit:</h3>
                        <p id={styles.profit}>{tipster.profit}€</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button onClick={(e) => handleFollow(e, tipster.id)}>
                      {tipster.appuser_set.includes(userData?.["data"][0]["id"])
                        ? "Unfollow"
                        : "Follow"}
                    </button>
                  </div>
                </li>
              </Link>
            ))}
          </>
        </ul>
      </div>
    </div>
  );
}

export default NewStats;
