import { default as ApexStockChart } from "react-apexcharts";
import TipsTable from "../../components/TipsTable/TipsTable";
import styles from "./NewStatsDetail.module.css";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";

import { useContext } from "react";
import { useParams } from "react-router-dom";
import AuthenticationProvider from "../../contexts/AuthenticationContext";

function NewStatsDetail() {
  const { id } = useParams();
  const { user, backendURL } = useContext(AuthenticationProvider);
  const user_id = user?.["user_id"] ? user["user_id"] : 0;

  const { data: chartData, loading: isLoading } = useSendGetRequest("chart-tipster-data/");
  const { data, loading } = useSendGetRequest(`tipster-details/${id}/${user_id}/`);

  if (isLoading || loading) return <SpinnerFullPage />;

  const options = {
    options: {
      chart: {
        type: "line",

        animations: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "$" + value.toFixed(0);
            },
          },
        },
      },
    },
    series: [
      {
        name: `Total Profit`,
        data: chartData["data"].map((data) => ({
          x: data.date,
          y: data.profit,
        })),
      },
    ],
  };

  const tipsterData = data["data"]["tipster"][0];
  console.log(tipsterData);
  return (
    <div>
      <div className={styles.tipsterInfo}>
        <div className={styles.tipster}>
          <img
            src={`${backendURL.replace("/api/", "")}${tipsterData.profile_picture}`}
            alt="tipster"
          />
          <div className={styles.rightText}>
            <h3>{tipsterData.username}</h3>
            <p id={styles.sport}>{tipsterData.sports}</p>
          </div>
        </div>
        <div className={styles.info}>
          <div>
            <h3>Analysis</h3>
            <p>{tipsterData.number_of_analysis}</p>
          </div>
          <div>
            <h3>Wins</h3>
            <p>{tipsterData.wins}</p>
          </div>
          <div>
            <h3>Losses</h3>
            <p>{tipsterData.losses}</p>
          </div>
          <div>
            <h3>Avg.odd</h3>
            <p>{tipsterData.average_odds}</p>
          </div>
          <div>
            <h3>ROI</h3>
            <p>{tipsterData.roi}</p>
          </div>
          <div>
            <h3>Profit</h3>
            <p>{tipsterData.profit}</p>
          </div>
        </div>
      </div>
      <div id={styles.chart}>
        <ApexStockChart
          options={options.options}
          series={options.series}
          type="line"
          height="300px"
        />
      </div>
      <TipsTable lock={true} data={data.data.tips} />
    </div>
  );
}

export default NewStatsDetail;
