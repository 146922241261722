import styles from "./MembershipPlans.module.css";
import MasterCard from "../../Images/mastercard.svg";
import Visa from "../../Images/visa.svg";
import googlePay from "../../Images/googlePay.svg";
import applePay from "../../Images/applePay.svg";
import check from "../../Images/check.svg";

function MembershipPackage({ mode, onClick, title, price }) {
  return (
    <div className={`${styles.package} ${mode === "dark" && styles.packageDark}`}>
      <div className={styles.header}>
        <img src={MasterCard} alt="mastercard" />
        <h3>{title}</h3>
      </div>
      <div className={styles.description}>
        <p>what’s include</p>
        <div className={styles.descriptionLine}>
          <img src={check} alt="check" />
          <p>Access to actual tips from all tipsters</p>
        </div>
        <div className={styles.descriptionLine}>
          <img src={check} alt="check" />
          <p>Email notifications of new tips</p>
        </div>
      </div>
      <button className={styles.buyBtn} onClick={onClick}>
        Just in <span> {price}€</span>
      </button>
      <div className={styles.paymentMethods}>
        <img src={MasterCard} alt="mastercard" />
        <img src={Visa} alt="Visa" />
        <img src={applePay} alt="applePay" />
        <img src={googlePay} alt="googlePay" />
      </div>
    </div>
  );
}

export default MembershipPackage;
