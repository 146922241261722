import { useEffect, useState } from "react";

function useSendGetRequest(URL) {
  // const backendURL = "http://127.0.0.1:8000/api/";
  const backendURL = "https://topmatchpredict-f1c26a0a7d2a.herokuapp.com/api/";

  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(
    function () {
      async function fetchData() {
        try {
          setLoading(true);
          const response = await fetch(`${backendURL}${URL}`);
          const data = await response.json();

          if (!response.ok) {
            if (data["errorMsg"]) {
              throw new Error(data["errorMsg"]);
            } else {
              throw new Error("Error happened while fetching data.");
            }
          }
          setData(data);
          if (data["successMsg"]) {
            setSuccessMsg(data["successMsg"]);
          } else {
            setSuccessMsg("Data fetched successfully!");
          }
        } catch (error) {
          setErrorMsg(error.message);
        } finally {
          setLoading(false);
        }
      }
      fetchData();
    },
    [URL, backendURL]
  );

  return { successMsg, errorMsg, loading, data, setLoading };
}

export default useSendGetRequest;
