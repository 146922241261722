import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// pages
import Register from "./pages/Register/Register.js";
import Login from "./pages/Login/Login.js";

import NewHome from "./pages/NewHomePage/Home";

import NewTips from "./pages/NewTips/NewTips.js";
import Faq from "./pages/FAQ/Faq.js";

// components
import Navbar from "./components/Navbar/Navbar.js";

// contexts
import { AuthenticationProvider } from "./contexts/AuthenticationContext.js";
import { PrimeReactProvider } from "primereact/api";
import { TipsProvider } from "./contexts/TipsContext.js";
import Tip from "./pages/Tip/Tip.js";
import AgeRestrictionImg from "./Images/18+.svg";
import TopInfo from "./components/TopInfo/TopInfo.js";
import NewStats from "./pages/NewStats/NewStats.js";
import NewStatsDetail from "./pages/NewStatsDetail/NewStatsDetail.js";
function App() {
  return (
    <Router>
      <AuthenticationProvider>
        <TipsProvider>
          <PrimeReactProvider>
            <Navbar />
            <TopInfo />
            <Routes>
              <Route exact path="/" element={<NewHome />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/tips" element={<NewTips />} />
              <Route exact path="/tips/tip/:id" element={<Tip />} />
              <Route exact path="/stats" element={<NewStats />} />

              <Route exact path="/faq" element={<Faq />} />
              <Route path="/tipster/:id" element={<NewStatsDetail />} />
            </Routes>
            <div id="bottomMsg">
              <img src={AgeRestrictionImg} alt="18+" />
              <p>
                The webiste is intendend for person over 18 years of age. We do not gurantee any
                reception or profit.
              </p>
            </div>
          </PrimeReactProvider>
        </TipsProvider>
      </AuthenticationProvider>
    </Router>
  );
}

export default App;
