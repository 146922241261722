import { useEffect, useState } from "react";
import styles from "./TopInfo.module.css";

function TopInfo() {
  const [time, setTime] = useState();

  useEffect(() => {
    setInterval(() => {
      const dateObject = new Date();

      const hour = dateObject.getHours();
      const getMinute = dateObject.getMinutes();
      const minute = getMinute < 10 ? "0" + getMinute : getMinute;

      const currentTime = hour + ":" + minute;

      setTime(currentTime);
    }, 1000);
  }, []);

  return (
    <div className={styles.infoWrapper}>
      <div></div>
      <div className={styles.infos}>
        <div className={styles.info}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 0H7C3.13401 0 0 3.13401 0 7V21C0 24.866 3.13401 28 7 28H21C24.866 28 28 24.866 28 21V7C28 3.13401 24.866 0 21 0Z"
              fill="#EBEBEB"
            />
            <path
              d="M14 21.5C18.1421 21.5 21.5 18.1421 21.5 14C21.5 9.85786 18.1421 6.5 14 6.5C9.85786 6.5 6.5 9.85786 6.5 14C6.5 18.1421 9.85786 21.5 14 21.5Z"
              fill="#045ED6"
            />
            <path
              d="M14 10.5V15L17 16.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>{time}</p>
        </div>

        <span></span>
        <div className={styles.info}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 0H7C3.13401 0 0 3.13401 0 7V21C0 24.866 3.13401 28 7 28H21C24.866 28 28 24.866 28 21V7C28 3.13401 24.866 0 21 0Z"
              fill="#EBEBEB"
            />
            <path
              d="M8 8H20C20.825 8 21.5 8.675 21.5 9.5V18.5C21.5 19.325 20.825 20 20 20H8C7.175 20 6.5 19.325 6.5 18.5V9.5C6.5 8.675 7.175 8 8 8Z"
              fill="#045ED6"
            />
            <path
              d="M20 10L14 15L8 10"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>admin@topmatchpredict.com</p>
        </div>
      </div>
    </div>
  );
}

export default TopInfo;
