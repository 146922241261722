import footBallIcon from "../../Images/football.png";
import basketBallIcon from "../../Images/basketball.png";
import Hockey from "../../Images/ice hockey.png";
import Handball from "../../Images/handball.png";
import Others from "../../Images/others.png";
import Tennis from "../../Images/tennis.png";
import Volleyball from "../../Images/volleyball.png";
import styles from "./TipsTable.module.css";

function SportIcon({ sport }) {
  let img = Others;
  console.log(sport);

  if (sport === "football") img = footBallIcon;
  if (sport === "basketball") img = basketBallIcon;
  if (sport === "ice hockey") img = Hockey;
  if (sport === "handball") img = Handball;
  if (sport === "tennis") img = Tennis;
  if (sport === "volleyball") img = Volleyball;

  return (
    <>
      <img src={img} alt="sport ball" className={styles.sportBall} />
    </>
  );
}

export default SportIcon;
