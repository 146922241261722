import { createContext, useContext } from "react";
import useSendGetRequest from "../hooks/useSendGetRequest";
import AuthenticationProvider from "./AuthenticationContext";

const TipsContext = createContext();

export default TipsContext;

export const TipsProvider = ({ children }) => {
  const { user } = useContext(AuthenticationProvider);
  const user_id = user?.["user_id"] ? user["user_id"] : 0;
  const { data, loading, errorMsg } = useSendGetRequest(`get-list-tips/${user_id}/`);
  const contextData = {
    errorMsg,
    data,
    loading,
  };

  return <TipsContext.Provider value={contextData}>{children}</TipsContext.Provider>;
};
