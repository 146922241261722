import { useState, useContext } from "react";
import { Link } from "react-router-dom";

// COMPONENTS
import PasswordInput from "../../components/ReusableComponents/PasswordField/PasswordInput";
import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ReusableComponents/ErrorMessage/ErrorMessage";

// STYLE
import styles from "./Register.module.css";
import AuthenticationContext from "../../contexts/AuthenticationContext";

function Register() {
  const [passwordValue, setPassword] = useState();
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const { loginUser, backendURL } = useContext(AuthenticationContext);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(`${backendURL}register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: e.target.email.value,
          username: e.target.username.value,
          password: e.target.password.value,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data);
      }
      setError(null);
      loginUser(e);
    } catch (error) {
      setError(error.message);
      console.error(error);
    } finally {
      setLoading(null);
    }
  }

  return (
    <div className={styles.register}>
      {loading && <SpinnerFullPage />}
      {!loading && (
        <>
          <h1>Register</h1>
          <form onSubmit={handleSubmit} className={styles.registerForm}>
            <div>
              <label>Username</label>
              <input type="text" placeholder="Username" name="username" required />
            </div>
            <div>
              <label>Email</label>
              <input type="email" placeholder="Email" name="email" required />
            </div>
            <div>
              <label>Password</label>
              <PasswordInput
                passwordValue={passwordValue}
                handleChange={() => setPassword()}
                eyeColor="black"
              />
            </div>

            {error && <ErrorMessage errorMessage={error} />}
            <p>
              Already a user? <Link to="/login">Log in!</Link>
            </p>
            <button type="submit" className="standard-btn">
              Register
            </button>
          </form>
        </>
      )}
    </div>
  );
}

export default Register;
