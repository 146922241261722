import styles from "./MiddleStats.module.css";

function MiddleStats({ websiteStats }) {
  return (
    <div className={styles.middleStats}>
      <div className={styles.stat}>
        <h3>{new Intl.NumberFormat("ja-JP").format(websiteStats.total_tips)}</h3>
        <p>Number of Total Tips since 2024</p>
      </div>
      <span></span>
      <div className={styles.stat}>
        <h3>{new Intl.NumberFormat("ja-JP").format(websiteStats.active_tips)}€</h3>
        <p>Total Profit when you bet 50€/unit</p>
      </div>
    </div>
  );
}

export default MiddleStats;
