import Hero from "./Hero";
import Unique from "./Unique";
import MembershipPlans from "./MembershipPlans";
import MiddleStats from "./MiddleStats";
import { useContext, useState } from "react";
import TipsProvider from "../../contexts/TipsContext";
import AuthenticationProvider from "../../contexts/AuthenticationContext";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import ErrorMessage from "../../components/ReusableComponents/ErrorMessage/ErrorMessage";
import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import Modal from "./Modal";

function NewHome() {
  const { user, loading, backendURL } = useContext(AuthenticationProvider);
  const [emailError, setEmailError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loadingEmail, setLoadingEmail] = useState(null);
  const [showModal, setShowModal] = useState();
  const { data, loading: isLoading, errorMsg } = useContext(TipsProvider);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [plan, setPlan] = useState();

  async function handleBuy(planSelected) {
    setShowModal(true);
    if (!user) {
      return;
    }
    if (user && !showModal && !plan) {
      setPlan(planSelected);
      return;
    }
    try {
      setLoadingEmail(true);
      const response = await fetch(`${backendURL}buy/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: user["username"],
          plan,
          paymentMethod,
        }),
      });

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        throw new Error("Username or password is not correct! Please double check and try again.");
      }

      setSuccessMsg(data["successMsg"]);

      setTimeout(function () {
        setSuccessMsg(null);
      }, 2000);

      setPlan("");
      setShowModal(false);
    } catch (error) {
      setEmailError(error.message);
      console.error(error);
      setTimeout(function () {
        setEmailError(null);
      }, 2000);
    } finally {
      setLoadingEmail(null);
    }
  }

  const {
    data: homePageStats,
    error,
    loading: statsLoading,
  } = useSendGetRequest("get-homepage-stats/");

  console.log(homePageStats);
  if (loading || isLoading || !data || loadingEmail || statsLoading) return <SpinnerFullPage />;
  if (errorMsg || error) return <ErrorMessage errorMsg={errorMsg || error} />;

  const websiteStats = homePageStats?.["data"]?.[0];
  return (
    <div>
      {showModal && (
        <Modal
          setLoadingEmail={setLoadingEmail}
          paymentMethod={paymentMethod}
          setPlan={setPlan}
          plan={plan}
          setSuccessMsg={setSuccessMsg}
          setEmailError={setEmailError}
          showModal={showModal}
          setPaymentMethod={setPaymentMethod}
          setShowModal={setShowModal}
          handleBuy={handleBuy}
        />
      )}
      <Hero emailError={emailError} successMsg={successMsg} />
      <MiddleStats websiteStats={websiteStats} />
      <Unique />
      <MembershipPlans handleBuy={handleBuy} />
    </div>
  );
}

export default NewHome;
