import { useContext, useEffect, useRef } from "react";
import styles from "./Modal.module.css";
import { Link } from "react-router-dom";
import AuthenticationProvider from "../../contexts/AuthenticationContext";

function Modal({ setPaymentMethod, setShowModal, showModal, handleBuy, plan }) {
  const { user } = useContext(AuthenticationProvider);

  const ref = useRef();
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setShowModal]);

  return (
    <div>
      {showModal && user && (
        <div className={styles.modalBuy} ref={ref}>
          <h1>Please choose prefered payment option.</h1>
          <div>
            <label>Master/Visa/Credit Card</label>
            <input
              type="radio"
              name="payment"
              value="card"
              checked
              onClick={() => setPaymentMethod("card")}
            />
          </div>
          <div>
            <label>Bitcoin</label>
            <input
              type="radio"
              name="payment"
              value="btc"
              onClick={() => setPaymentMethod("bitcoin")}
            />
          </div>
          <button className="standard-btn" onClick={() => handleBuy(plan)}>
            Order
          </button>
        </div>
      )}
      {showModal && !user && (
        <div className={styles.modal} ref={ref}>
          <h1>Please log in in order to buy a package.</h1>
          <Link id={styles.modalLink} to="register">
            Register
          </Link>
        </div>
      )}
    </div>
  );
}

export default Modal;
