import styles from "./Hero.module.css";
import Footballer from "../../Images/footballer.png";
import Background from "../../Images/homepage-background.jpeg";
import ErrorMessage from "../../components/ReusableComponents/ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";

function Hero({ emailError, successMsg }) {
  const navigate = useNavigate();
  return (
    <div className={styles.hero}>
      {emailError && <ErrorMessage errorMessage={emailError} />}

      {successMsg && <p id="successMsg">{successMsg}</p>}
      <img src={Background} alt="background" id={styles.background} />
      <div className={styles.text}>
        <h1>the best </h1>
        <h1>tipsters at one place</h1>
        <p>
          Connect with the number one in betting advice! At Topmatchpredict verification you will
          find a community of the best bettors. Start making money with odds betting. Join us and
          you can get started.
        </p>
        <button onClick={() => navigate("/register")}>Join now</button>
        <img src={Footballer} alt="footballer" id={styles.footballer} />
      </div>
    </div>
  );
}

export default Hero;
