import correct from "../../Images/correct.svg";
import wrong from "../../Images/wrong.png";
import lockIcon from "../../Images/lock.svg";

import styles from "./TipsTable.module.css";
import { useContext, useState } from "react";
import AuthenticationProvider from "../../contexts/AuthenticationContext";
import SpinnerFullPage from "../ReusableComponents/FullPageLoader/SpinnerFullPage";
import { Link } from "react-router-dom";
import SportIcon from "./SportIcon";
import Calendar from "../../Images/calendar.svg";

function TipsTable({ data, lock = false }) {
  const { data: userData, loading } = useContext(AuthenticationProvider);

  let paid = false;

  const numberOfRowsPerTablePage = 5;
  const [tipsData, setTipsData] = useState(data?.slice(0, numberOfRowsPerTablePage));
  const numOfPaginationBtns = Math.ceil(data?.length / numberOfRowsPerTablePage);

  function handleNextTable(num) {
    setTipsData(data.slice((num - 1) * numberOfRowsPerTablePage, num * numberOfRowsPerTablePage));
  }

  if (loading) return <SpinnerFullPage />;
  if (userData) {
    paid = userData["data"][0]["paid"];
  }
  const tipsType = lock ? "Active" : "Last";
  console.log(tipsData);
  return (
    <div className={styles.activeTips}>
      <h3 className={styles.tipHeading}>{tipsType} tips</h3>
      <ul className={styles.tipsList}>
        <>
          {tipsData?.map((tip) => (
            <Link to={`/tips/tip/${tip.id}`} key={tip.id}>
              <li className={styles.tip}>
                {!paid && lock ? (
                  <div className={styles.lockedTip}>
                    <div className={styles.left}>
                      <img src={lockIcon} alt="lock" id={styles.lock} />
                      <p>This Active Tip is Locked!</p>
                    </div>
                    <button id={styles.buy}>Buy</button>
                  </div>
                ) : (
                  <>
                    <div className={styles.right}>
                      <SportIcon sport={"football"} id={styles.sportIcon} />
                      {/* <SportIcon sport={tip.sport} /> */}

                      <div className={styles.rightText}>
                        <div>
                          <h3 id={styles.matchName}>{tip.match_name}</h3>
                          <p id={styles.dateTime}>
                            {!tip.active && (
                              <>
                                {tip.correct_tip ? (
                                  <img src={correct} alt="correct" />
                                ) : (
                                  <img src={wrong} alt="wrong" />
                                )}
                              </>
                            )}
                          </p>
                        </div>

                        <div className={styles.time}>
                          <img src={Calendar} alt="calendar" />
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                            .format(new Date(tip.date))
                            .split("/")
                            .join("-")}
                          ,{" "}
                          {new Date(tip.date).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </div>
                      </div>
                    </div>
                    <div className={styles.infoWrap} id={styles.first}>
                      <div className={styles.data} id={styles.tipster}>
                        <h3>Tipster </h3>
                        <p>{tip.username}</p>
                      </div>
                      <div className={styles.data} id={styles.roi}>
                        <h3>ROI</h3>
                        <p>{tip.roi}%</p>
                      </div>
                    </div>
                    <div className={styles.infoWrap} id={styles.second}>
                      <div className={styles.data}>
                        <h3> Tip</h3> <p>{tip.tip}</p>
                      </div>
                      <div className={styles.data}>
                        <h3>Odd</h3> <p>{tip.odd}</p>
                      </div>
                      <div className={styles.data}>
                        <h3>Unit</h3> <p>{tip.unit}</p>
                      </div>
                      <div className={styles.data}>
                        <h3>Bookmaker</h3>
                        <p> {tip.bookmaker}</p>
                      </div>
                      <div className={`${styles.last} ${tip.profit_or_loss < 0 && styles.loss}`}>
                        <p>{tip.profit_or_loss}</p>
                      </div>
                    </div>
                  </>
                )}
              </li>
            </Link>
          ))}
          <div className={styles.pagination}>
            {Array.from({ length: numOfPaginationBtns }, (_, i) => (
              <button onClick={() => handleNextTable(i + 1)} className={styles.paginationBtn}>
                {i + 1}
              </button>
            ))}
          </div>
        </>
      </ul>
    </div>
  );
}

export default TipsTable;
